<template>
    <v-row no-gutters justify="center" style="height: 100%" align="center">
        <v-col style="text-align: center" cols="12">
            <v-card elevation="2" class="my-6">
                <v-app-bar color="teal darken-2" dark flat dense>
                    <v-app-bar-title>Sign in to access your account</v-app-bar-title>
                </v-app-bar>
                <v-card-text>
                    <v-btn :to="loginRoute" elevation="2" color="teal darken-2 white--text" class="my-6" outlined>Continue</v-btn>
                    <p>No account? <a :href="mainWebsiteURL">Sign up</a></p>
                </v-card-text>
            </v-card>
            <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Emplus</a></p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['nextRoute'], // caller can pass `this.$router.currentRoute.fullPath` as the value of this prop to return to same page after login
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://emplus.io';
        },
        loginRoute() {
            if (this.nextRoute) {
                return { name: 'login', query: { next: this.nextRoute } };
            }
            return { name: 'login' };
        },
    },
};
</script>
